import React from "react";

import { Layout, SEO } from "../../../components/structure";
import {
  Button,
  Content,
  Hero,
  HospitalityPremiumUpgrade,
  Testimonial,
  CallToAction,
  HospitalitySplitBlock,
  HospitalityPartnerDashboard,
} from "../../../components/blocks";
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../styles";
import GatsbyImage from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

const HospitalityStayExtendPage = props => {
  const pageQuery = useStaticQuery(graphql`
    query {
      hero: file(base: { eq: "GettyImages-1400267055.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIcon: file(base: { eq: "arrow-right-blue.png" }) {
        ...ButtonIcon
      }
      rightArrowIconWhite: file(base: { eq: "arrow-right.png" }) {
        ...ButtonIcon
      }
      airIcon: file(base: { eq: "AirplaneIcon.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      productOverview: file(base: { eq: "StayExtended-Mobile-2023.png" }) {
        childImageSharp {
          fixed(width: 270, height: 515, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      stayExtendIcon: file(
        base: { eq: "Icons_Product_hospitality_final_StayExtend.png" }
      ) {
        childImageSharp {
          fixed(width: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      clickUpgradeIcon: file(base: { eq: "click-upgrade.png" }) {
        childImageSharp {
          fixed(width: 45, height: 80, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      partnerIncrease: file(base: { eq: "partnerIncrease.png" }) {
        childImageSharp {
          fixed(width: 150, height: 150, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      revenueIncrease: file(base: { eq: "revenueIncrease.png" }) {
        childImageSharp {
          fixed(width: 150, height: 150, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      cloudUpload: file(base: { eq: "cloudUpload.png" }) {
        childImageSharp {
          fixed(width: 70, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      creditCard: file(base: { eq: "creditCard.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      lcrProductImage: file(base: { eq: "LCR-Device-SWA.png" }) {
        childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      loyaltyIcon: file(base: { eq: "handPlus.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      speedPassProduct: file(base: { eq: "Product-Speed-Pass.png" }) {
        childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      offerDistribute: file(base: { eq: "offerDistribute.png" }) {
        childImageSharp {
          fixed(width: 70, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      revPlatformBackground: file(
        base: { eq: "IndustryCruiseRevenuePlatform.jpg" }
      ) {
        childImageSharp {
          fixed(width: 1100, height: 700, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      airTestimonials: file(base: { eq: "IndustryAirTestimonials.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 600, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      growthArrow: file(base: { eq: "ArrowClimber.png" }) {
        childImageSharp {
          fixed(width: 500, height: 300, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      allHands: file(base: { eq: "aboutAllHands.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 400, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      mobileallHands: file(base: { eq: "aboutAllHands.jpg" }) {
        childImageSharp {
          fixed(width: 1000, height: 300, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      airCanada: file(base: { eq: "AC_Inline.png" }) {
        ...CruiseLogo
      }
      lingus: file(base: { eq: "AerLingus_Inline.png" }) {
        ...CruiseLogo
      }
      latam: file(base: { eq: "Latam_Inline.png" }) {
        ...CruiseLogo
      }
      va: file(base: { eq: "VA_Inline.png" }) {
        ...CruiseLogo
      }
      lufthansa: file(base: { eq: "Lufthansa_Inline.png" }) {
        ...CruiseLogo
      }
      singapore: file(base: { eq: "Singapore_Inline.png" }) {
        ...CruiseLogo
      }
      etihad: file(base: { eq: "EtihadGold.png" }) {
        ...CruiseLogo
      }
      qantas: file(base: { eq: "Qantas.png" }) {
        ...CruiseLogo
      }
      whiteLabelIcon: file(base: { eq: "55.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      fastestTimeIcon: file(base: { eq: "79.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      riskFreeIcon: file(base: { eq: "163.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      dataDrivenIcon: file(base: { eq: "41.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      FastIntegrationIcon: file(base: { eq: "93.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      provenRevenueIcon: file(base: { eq: "101.png" }) {
        childImageSharp {
          fixed(height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      simpleUX: file(base: { eq: "simpleUX.png" }) {
        childImageSharp {
          fixed(width: 50, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      generateAncillary: file(base: { eq: "generateAncillary.png" }) {
        childImageSharp {
          fixed(width: 58, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      inventoryControl: file(base: { eq: "inventoryControl.png" }) {
        childImageSharp {
          fixed(width: 50, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      HeroRightArrowTriColor: file(
        base: { eq: "HorizontalWebArrowTriColour.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const ProductsHeroContainer = styled.div`
    width: 100%;
    max-width: 1400px;
    /*margin: 80px auto;*/
    margin: 80px auto;
    margin-top: 0;
    margin-bottom: 0;
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2%;

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
    }

    > div {
      flex-basis: 48%;
    }

    .graphic-con {
      width: 100%;
      max-width: 700px;
      height: 300px;

      @media only screen and ${mq.maxMd} {
        align-self: flex-start;
      }

      .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .title-con {
      h2 {
        white-space: pre-wrap;
        font-family: ${font.family.sherika};
        font-weight: 700;
        font-size: 80px;
        color: ${brand.colors.pg_orange};
        padding-top: 135px;
        @media only screen and ${mq.maxMd} {
          text-align: center;
          font-size: 60px;
        }
      }
    }
  `;

  const CruiseParnters = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 50px auto;

    .cruise-logo-container {
      width: 95%;
      max-width: 1400px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 40px;

      @media only screen and ${mq.maxMd} {
        flex-wrap: wrap;
        justify-content: center;
      }

      .logo-con {
        flex-basis: 200px;
      }
    }
  `;

  const PlatformDetailContainer = styled.div`
    width: 100%;
    background-color: ${brand.colors.pg_blue};

    .inner-icon-container {
      width: 95%;
      max-width: 1020px;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 80px 0;
      gap: 40px;

      @media only screen and ${mq.maxMd} {
        width: 80%;
      }
    }

    .icon-con {
      .gatsby-image-wrapper {
        filter: brightness(0) saturate(100%) invert(32%) sepia(100%)
          saturate(1931%) hue-rotate(210deg) brightness(89%) contrast(111%);
      }
    }

    .platform-headline {
      display: inline-block;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      margin-top: 2rem;

      /*
       .headline-con {
         margin-bottom:0;
       }
       */
    }

    ${props =>
      props.lightGreyBg &&
      css`
        background-color: ${brand.colors.pg_primary_light};
      `}
  `;

  return (
    <Layout>
      <SEO
        title="StayExtend | Plusgrade"
        description={
          "Plusgrade accelerates your incredible passenger experiences while generating significant ancillary revenue on every flight."
        }
        keywords={"airline, flight, upgrades"}
      />
      <Content whiteBG>
        <Hero
          title={"StayExtend"}
          image={pageQuery["hero"]}
          subtitle={
            "Enhance your guests' flexibility and optimize your revenue with our early check-in and late check-out solution."
          }
          buttonURL={"/industries/hospitality/demo/"}
          buttonLabel={"Get started"}
          buttonIcon={pageQuery["rightArrowIconWhite"]}
          fullBG
        />

        <HospitalityPremiumUpgrade
          title={"Product overview"}
          copy={
            "Give your guests the ability to customize their stay duration by monetizing early check-ins, late check-outs and stay extensions. <strong>StayExtend</strong> increases your revenue while giving your guest an exceptional customer experience."
          }
          buttonDest={"/industries/hospitality/demo/"}
          buttonLabel={"Request a demo"}
          image={pageQuery["productOverview"]}
          icon={pageQuery["stayExtendIcon"]}
          buttonIcon={pageQuery["rightArrowIconWhite"]}
          gatsbyWrapper={"500px"}
        ></HospitalityPremiumUpgrade>

        <PlatformDetailContainer lightGreyBg>
          <div className="inner-icon-container">
            <IconContainer
              darkOnWhite
              icon={pageQuery["whiteLabelIcon"]}
              headline={"White-label solutions"}
              copy={
                "Hotel-branded solution that provides a seamless customer experience."
              }
            />

            <IconContainer
              darkOnWhite
              icon={pageQuery["fastestTimeIcon"]}
              headline={"Fastest time-to-market"}
              copy={
                "Start generating incremental revenue in as little as three days."
              }
            />

            <IconContainer
              darkOnWhite
              icon={pageQuery["riskFreeIcon"]}
              headline={"Risk-free investment"}
              copy={"Zero implementation fees or upfront costs."}
            />

            <IconContainer
              darkOnWhite
              icon={pageQuery["dataDrivenIcon"]}
              headline={"Data-driven decisioning"}
              copy={
                "Robust business rules and levers designed to optimize your upgrade program."
              }
            />

            <IconContainer
              darkOnWhite
              icon={pageQuery["FastIntegrationIcon"]}
              headline={"Fast integrations"}
              copy={
                "Easy implementation with your existing PMS and channel management systems."
              }
            />

            <IconContainer
              darkOnWhite
              icon={pageQuery["provenRevenueIcon"]}
              headline={"Proven revenue growth"}
              copy={
                "Maximize profit with diversified products and revenue streams."
              }
            />
          </div>
        </PlatformDetailContainer>

        <Content noMargin>
          <ProductsHeroContainer>
            <div className="graphic-con">
              <GatsbyImage
                fluid={
                  pageQuery["HeroRightArrowTriColor"].childImageSharp.fluid
                }
                imgStyle={{ objectPosition: "right center" }}
              />
            </div>
            <div className="title-con">
              <h2>{"Effortless\nintegration"}</h2>
            </div>
          </ProductsHeroContainer>
        </Content>

        <Content orangeBG>
          <HospitalitySplitBlock
            copy={
              "Transform your hotel operations with our upselling solutions. Designed for seamless integration with your current brand and property management system, our solution automates upselling to supercharge your revenue potential while enhancing your guest experiences. "
            }
            buttonLabel={"See all integrations"}
            graphicStyle={{ objectPosition: "center top" }}
            image={pageQuery["speedPassProduct"]}
            id="speed-pass2"
            buttonDestination={"/industries/hospitality/integrations"}
            style={{ marginTop: "-60px" }}
            productPage
          />
        </Content>

        <CallToAction
          title={"Drive ancillary revenue with\nincredible guest experiences"}
          subtitle={"Contact us today to learn more."}
        >
          <Button
            destination={"/industries/hospitality/demo/"}
            label={"Request a demo"}
            icon={pageQuery["rightArrowIcon"]}
            white
            blueText
            pill
          />
        </CallToAction>
      </Content>
    </Layout>
  );
};

export default HospitalityStayExtendPage;

const IconCon = styled.div`

${font.imports.sherika}
  flex-basis: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and ${mq.maxMd} {
    flex-basis: 95%;
  }

  .icon-con {
    @media only screen and ${mq.maxMd} {
      text-align: center;
      margin: auto;
    }

  }

  .headline-con {
    @media only screen and ${mq.maxMd} {
      text-align: center;
      margin: auto;
    }
  }

  .headline {
    font-family: ${font.family.sherika};
    font-weight: 600 !important;
    color: ${brand.colors.pg_orange} !important;
    font-size: 48px !important;
    margin-bottom: 1px;
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  .headline-highlight {
    color: ${brand.colors.pg_primary_light};
    margin-top: 30px;
  }

  p{
    margin-top: 1px;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    color: ${brand.colors.pg_primary_light};
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  ${props =>
    props.whiteOrange &&
    css`
      flex-basis: 30%;
      align-items: flex-start;
      flex-wrap: wrap;

      .headline-con {
        text-align: left;
        h4 {
          color: ${brand.colors.pg_primary_dark} !important;
          font-family: ${font.family.sherika};
          font-size: 22px !important;
          font-weight: 700;
          white-space: pre-line;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }
        p {
          color: ${brand.colors.pg_primary_dark};
          font-family: ${font.family.sherika};
          font-weight: 400;
          margin: 20px 0;
          min-height: 96px;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }

        .headline {
          min-height: 60px;
          display: flex;
          align-items: center;
          @media only screen and ${mq.maxMd} {
            text-align: center;
            justify-content: center;
          }
        }
      }
    `}

  ${props =>
    props.whiteOnBlue &&
    css`
      flex-basis: 45%;
      align-items: flex-start;
      justify-content: center;
      text-align: left;

      .icon-con {
        text-align: left;
      }

      .headline-con {
        .headline {
          color: #fff !important;
          font-size: 26px !important;
        }
      }

      p {
        font-size: 16px !important;
        font-weight: 400 !important;
        margin-bottom: 0;
      }
    `}

  ${props =>
    props.darkOnWhite &&
    css`
      flex-basis: 45%;
      align-items: flex-start;
      justify-content: center;
      text-align: left;

      .icon-con {
        text-align: left;
      }

      .headline-con {
        .headline {
          color: #080a12 !important;
          font-size: 26px !important;
        }
      }

      p {
        font-size: 16px !important;
        font-weight: 400 !important;
        margin-bottom: 0;
        color: #080a12 !important;
      }
    `}

`;

const IconContainer = ({ icon, highlight, headline, copy, ...props }) => {
  const ic = icon ? <GatsbyImage fixed={icon.childImageSharp.fixed} /> : <></>;
  return (
    <IconCon {...props}>
      <div className="icon-con">{ic}</div>
      <div className="headline-con">
        <h4 className="headline-highlight">{highlight}</h4>
        <h4 className="headline">{headline}</h4>
        <p dangerouslySetInnerHTML={{ __html: copy }}></p>
      </div>
    </IconCon>
  );
};

export const query = graphql`
  fragment CruiseLogo on File {
    childImageSharp {
      fluid(maxWidth: 125, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
